<template>
  <div>
    <button type="button" @click="show = !show"
            class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
      <i class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
    </button>
    <ModalBase :width="'w-95por lg:w-70por'" :show="show" @close="show = false">
      <form @submit.prevent="store">
        <Request model="AreaAmounts" action="byId" :form="areaAmountsFilter" @success="onSuccessGetAreaAmounts"
                 @error="onErrorGetAreaAmounts" ref="getAreaAmountsRef"/>
        <div class="flex flex-col h-full">
          <ModalHeader :type="'show'" :editable="editable" :entity="entity" @close="show = false"/>
          <div class="flex flex-col justify-center items-center w-full p-4 md:px-20">
            <label class="mt-1 w-full max-w-lg">
              <span>Nombre</span>
              <input class="form-input mt-1 w-full text-center text-bo-input"
                     :class="{'bg-gray-200 noselect' : !editable}"
                     placeholder="Nombre" v-model="form.name" ref="nameRef" :disabled="!editable" required>
            </label>
            <div class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
              <label class="w-auto" for="amount">
                <span>Importe</span>
              </label>
              <input type="number" min="0" step="0.01" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input"
                     :class="{'bg-gray-200 noselect' : !editable}"
                     placeholder="Objetivo" v-model="form.amount" id="amount"
                     :disabled="!editable" required>
            </div>
            <div class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
              <label class="w-auto" for="objective_amount">
                <span>Objetivo Importe</span>
              </label>
              <input type="number" min="0" step="0.01" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input"
                     :class="{'bg-gray-200 noselect' : !editable}"
                     placeholder="Objetivo" v-model="form.objective_amount" id="objective_amount"
                     :disabled="!editable" required>
            </div>
          </div>
        </div>
        <ModalFooter @close="show = false">
          <Request model="AreaAmounts" action="update" :form="form" @success="onSuccess" @error="onError"
                   ref="updateAreaAmountsRef"/>
          <ActionBtn v-show="!editable" :action="'edit'" @edit="edit"/>
          <ActionBtn v-show="editable" :action="'store'" @store="store"/>
        </ModalFooter>
      </form>
    </ModalBase>
  </div>
</template>

<script>
import {actions} from "@/store";
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";

export default {
  name: "Show",
  components: {
    Request, ModalBase, ModalHeader, ModalFooter, ActionBtn
  },
  props: ['entity'],
  data() {
    return {
      show: false,
      editable: false,
      form: {
        name: null,
        amount: null,
        objective_amount: null
      },
      areaAmountsFilter: {
        id: null
      }
    }
  },
  watch: {
    show(n) {
      if (n) {
        this.areaAmountsFilter.id = this.elSelected.id;
        this.$refs.getAreaAmountsRef.request();
        this.$refs.nameRef.disabled = true;
      } else {
        this.editable = false;
      }
    }
  },
  computed: {
    elSelected() {
      return actions.getElSelected();
    }
  },
  methods: {
    edit() {
      this.editable = !this.editable;
      if (this.editable) {
        this.$refs.nameRef.disabled = false;
        this.$refs.nameRef.focus();
      }
    },
    store() {
      this.$refs.updateAreaAmountsRef.request();
    },
    onError(errors) {
      this.toastErrors(errors)
    },
    onSuccess(response) {
      this.response = JSON.parse(JSON.stringify(response));
      this.$notify(
          {group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
          this.millis
      );
      this.$emit('refreshDataTable');
    },
    onSuccessGetAreaAmounts(areaAmounts) {
      this.setForm(areaAmounts);
    },
    onErrorGetAreaAmounts(errors) {
      this.toastErrors(errors)
    },
    setForm(areaAmounts) {
      console.log(areaAmounts.name);
      console.log(areaAmounts);
      this.form.id = areaAmounts.id;
      this.form.name = areaAmounts.name;
      this.form.amount = areaAmounts.amount;
      this.form.objective_amount = areaAmounts.objective_amount;
    }
  },
}
</script>

<style scoped>

</style>