<template>
  <All model="AreaAmounts" :immediate="true" ref="areasAmountsRef" v-slot="{data:areaAmounts}"
       :query="areaAmountsFilter" @loading:change="(event) => loading = event">
    <SectionWrapper>
      <div class="w-full px-1 sm:px-2 xl:px-5">
        <Header :title="title"/>
        <div class="py-2 w-full flex flex-row items-between justify-center">
          <perPageSelector @changeOption="changePerPage"/>
          <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
            <new-mod :entity="entity" @refreshDataTable="refreshDataTable"/>
            <show-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
            <destroy-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
          </div>
          <searcher @changeText="changeText"/>
        </div>
        <Table
            :data="areaAmounts.data" :fields="fields" :fieldBy="areaAmountsFilter.field"
            :direction="areaAmountsFilter.direction" :loading="loading"
            @changeField="changeField" @changeDirection="changeDirection">

          <template v-slot:customers_target_percent_reached="{ value }">
            <p v-if="value" class="py-4 px-2 truncate">{{ value | numberFormat(0) }}%</p>
          </template>
          <template v-slot:opportunities_plus_target_percent_reached="{ value }">
            <p v-if="value" class="py-4 px-2 truncate">{{ value | numberFormat(0) }}%</p>
          </template>

        </Table>
        <paginate v-if=areaAmounts.meta :meta=areaAmounts.meta @changePage="changePage"/>
        <PaginateTextInfo v-if="areaAmounts.meta" :meta="areaAmounts.meta" class="flex-auto text-center sm:hidden"/>
        <paginate-mobile :links="areaAmounts.links" @changePage="changePage" class="sm:hidden"/>
      </div>
    </SectionWrapper>
  </All>
</template>

<script>
import {All} from '@/api/components';
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/areaAmounts/Table";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import ShowMod from "@/pages/inside/sections/areaAmounts/modals/Show";
import NewMod from "@/pages/inside/sections/areaAmounts/modals/New";
import DestroyMod from "@/pages/inside/sections/areaAmounts/modals/Destroy";
import {actions, state} from "@/store";
import Header from "@/pages/inside/shared/datatables/Header";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";

export default {
  name: "AreaAmounts",
  components: {
    All,
    SectionWrapper,
    Header,
    Paginate,
    PaginateMobile,
    PerPageSelector,
    Searcher,
    Table,
    ShowMod,
    NewMod,
    DestroyMod,
    PaginateTextInfo
  },
  data() {
    return {
      title: 'Áreas Importe',
      entity: 'Área Importe',
      loading: false,
      areaAmountsFilter: {
        page: null,
        perPage: state.visibleRowsQtyInTable,
        field: 'name',
        direction: 'asc',
        text: null
      },
      fields,
    }
  },
  computed: {
    elSelected() {
      return actions.getElSelected();
    },
  },
  mounted() {
    actions.setElSelected(false);
  },
  methods: {
    refreshDataTable(keepSelection = false) {
      if (!keepSelection) {
        actions.setElSelected(false);
      }
      this.$refs.areasAmountsRef.request();
    },

    changePage(page) {
      this.areaAmountsFilter.page = page;
      this.refreshDataTable();
    },

    changePerPage(option) {
      this.areaAmountsFilter.perPage = option;
      this.areaAmountsFilter.page = 1;
      this.refreshDataTable();
    },

    changeField(field) {
      this.areaAmountsFilter.field = field;
      this.refreshDataTable();
    },

    changeDirection(direction) {
      this.areaAmountsFilter.direction = direction;
      this.refreshDataTable();
    },

    changeText(text) {
      this.areaAmountsFilter.page = null;
      this.areaAmountsFilter.text = text;
      this.refreshDataTable();
    },
  }
};

const fields = [
  {
    label: 'Área',
    key: 'name'
  },
  {
    label: 'Importe',
    key: 'amount',
    type: 'decimal',
    decimals:2
  },
  {
    label: 'Objectivo Importe',
    key: 'objective_amount',
    type: 'decimal',
    decimals:2
  },
];
</script>

<style scoped>

</style>