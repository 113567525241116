<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-minus text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por lg:w-50por'" :show="show" @close="show = false">
            <div class="flex flex-col h-full">
                <ModalHeader :type="'destroy'" :entity="entity" @close="show = false"/>
                <div class="flex flex-col p-4 sm:px-24">
                    <label class="mt-4">
                        <span>Si deseas su borrado presiona el botón de  <span class="font-semibold">Eliminar</span>.</span>
                    </label>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="AreaAmounts" action="destroy" :form="form" @success="onSuccess" @error="onError" ref="destroyAreaAmountsRef"/>
                <ActionBtn :action="'destroy'" @destroy="destroy"/>
            </ModalFooter>
        </ModalBase>
    </div>
</template>

<script>
import {Request} from "@/api/components";
import {actions} from "@/store";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";

export default {
    name: "Destroy",
    props: [ 'entity' ],
    components: {
        Request, ModalBase, ModalFooter, ModalHeader, ActionBtn
    },
    data() {
        return {
            show: false,
            isEditable: false,
            form: {
                id: null,
            }
        }
    },
    mounted() {
        let elSelected = actions.getElSelected();
        this.form.id  = elSelected.id;
    },
    methods: {
        destroy(){
            this.$refs.destroyAreaAmountsRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
            this.$emit('refreshDataTable');
            this.show = false;
        },
    }
}
</script>

<style scoped>

</style>