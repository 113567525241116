<template>
  <div>
    <button type="button" @click="show = !show"
            class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
      <i class="mdi mdi-plus text-2xl font-semibold leading-none"></i>
    </button>
    <ModalBase :width="'w-95por lg:w-70por'" :show="show" @close="show = false">
      <form @submit.prevent="store">
        <div class="flex flex-col h-full">
          <ModalHeader :type="'new'" :entity="entity" @close="show = false"/>
          <div class="flex flex-col justify-center items-center w-full p-4 md:px-20">
            <label class="mt-1 w-full max-w-lg">
              <span>Nombre</span>
              <input v-model="form.name" class="form-input mt-1 w-full text-center text-bo-input" placeholder="Nombre"
                     ref="nameRef" required>
            </label>
            <div class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
              <label class="w-auto" for="amount">
                <span>Importe</span>
              </label>
              <input type="number" min="0" step="0.01" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input"
                     placeholder="Importe" v-model="form.amount" id="amount"
                     required>
            </div>
            <div class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
              <label class="w-auto" for="objective_amount">
                <span>Objetivo Importe</span>
              </label>
              <input type="number" min="0" step="0.01" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input"
                     placeholder="Objetivo" v-model="form.objective_amount" id="objective_amount"
                     required>
            </div>
          </div>
        </div>
        <ModalFooter @close="show = false">
          <Request model="AreaAmounts" action="store" :form="form" @success="onSuccess" @error="onError"
                   ref="storeAreaAmountRef"/>
          <ActionBtn :action="'store'" @store="store"/>
        </ModalFooter>
      </form>
    </ModalBase>
  </div>
</template>

<script>
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";

export default {
  name: "New",
  components: {
    Request, ModalBase, ModalHeader, ModalFooter, ActionBtn
  },
  props: ['entity'],
  data() {
    return {
      show: false,
      form: {
        name: null,
        amount: null,
        objective_amount: null
      },
    }
  },
  methods: {
    store() {
      this.$refs.storeAreaAmountRef.request();
    },
    onError(errors) {
      this.toastErrors(errors)
    },
    onSuccess(response) {
      this.response = JSON.parse(JSON.stringify(response));
      this.$notify(
          {group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
          this.millis
      );
      this.$emit('refreshDataTable');
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>